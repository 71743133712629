import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import commercialData from '../data/commercial.json';

import Layout from '../components/Common/Layout/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Common/Hero/Hero';
import PropertyGroupList from '../components/Common/PropertyGroupList/PropertyGroupList';
import PropertyInquiryModal from '../components/Common/PropertyInquiryModal/PropertyInquiryModal';

const CommercialPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const propertyTitle = (title) => setModalTitle(title);

  return (
    <Layout>
      <PropertyInquiryModal
        propertyName={modalTitle}
        isOpen={isModalOpen}
        close={closeModal}
      />

      <Seo
        title="Commercial Properties"
        description="View Luxor Management's commercal property listings in Sudbury, ON."
      />

      <Hero
        background={
          <StaticImage
            src="../images/commercial-hero-bg.jpg"
            layout="fullWidth"
            placeholder="blurred"
            quality="90"
            alt=""
          />
        }
      >
        <h1 className="h-page-hero-title my-4 md:my-0">
          Your Investment, Our Priority
        </h1>
      </Hero>

      <PropertyGroupList
        inquireModalOpen={openModal}
        modalTitle={propertyTitle}
        propertyData={commercialData.commercial}
      />
    </Layout>
  );
};

export default CommercialPage;
