import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import './PropertyCard.css';

import ArrowRightIcon from '../Icons/24/ArrowRightIcon24';

const PropertyCard = ({ ...props }) => {
  const handleOpen = () => {
    props.inquireModalOpen();
    props.modalTitle(props.name);
  };

  return (
    <div
      className={classNames('c-property-card', {
        'c-property-card--no-inqiure': props.noInquire,
      })}
    >
      <GatsbyImage
        className="c-property-card__img"
        image={props.image}
        alt={props.name}
        backgroundColor="#D7D3D0"
      />
      <div className="c-property-card__data">
        <h3 className="text-lg font-bold">{props.name}</h3>
        {props.description && (
          <span className="text-brand-medium mt-3">{props.description}</span>
        )}
      </div>
      {!props.noInquire && (
        <button className="c-property-card-inquire-button" onClick={handleOpen}>
          <span className="mr-3">Inquire</span>
          <ArrowRightIcon className="fill-current" />
        </button>
      )}
    </div>
  );
};

export default PropertyCard;
