import React from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';

import './PropertyInquiryModal.css';

import CloseIcon from '../Icons/24/CloseIcon24';
import Form from '../Form/Form';
import Input from '../Input/Input';
import TextArea from '../Input/TextArea';
import Button from '../Button/Button';

const PropertyInquiryModal = ({ ...props }) => (
  <div
    className={classNames('c-property-inquiry-modal-container', {
      block: props.isOpen,
      hidden: !props.isOpen,
    })}
  >
    {props.isOpen && (
      <Helmet>
        <body className="no-scroll" />
      </Helmet>
    )}
    <div className="c-property-inquiry-modal-container__inner">
      <div className="c-property-inquiry-modal">
        <header className="c-property-inquiry-modal__header">
          <div className="text-xl font-bold flex-grow">Property Inquiry</div>
          <button type="button" onClick={props.close}>
            <CloseIcon className="fill-brand-medium" />
          </button>
        </header>

        <div className="max-w-sm mx-auto">
          <div className="c-property-inquiry-modal__property">
            {props.propertyName}
          </div>

          <Form
            formName="property-inquiry"
            successTitle="Inquiry sent"
            successMessage="Thank you for your inquiry. We will get back to you as soon as possible."
            errorTitle="Inquiry not sent"
            errorMessage="Sorry your inquiry has not been sent. Please try again."
            reset={!props.isOpen}
          >
            <input type="hidden" name="form-name" value="property-inquiry" />
            <input
              type="hidden"
              name="PropertyName"
              value={props.propertyName}
            />
            <Input
              className="mb-5"
              label="Full Name"
              type="text"
              name="FullName"
              placeholder="Enter your name…"
            />
            <Input
              className="mb-5"
              label="Email"
              type="email"
              name="Email"
              placeholder="Enter your email address…"
            />
            <Input
              optionalLabel
              className="mb-5"
              label="Phone"
              type="tel"
              name="Phone"
              placeholder="Enter your phone number…"
            />
            <TextArea
              optional
              className="mb-6"
              label="Message"
              name="Message"
              placeholder="Enter your message…"
            />
            <Button submit className="mx-auto" label="Send Inquiry" />
          </Form>
        </div>
      </div>
    </div>
  </div>
);

export default PropertyInquiryModal;
