import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

import './PropertyGroupList.css';

import PropertyCard from '../PropertyCard/PropertyCard';

const PropertyGroupList = ({ ...props }) => {
  const query = useStaticQuery(graphql`
    {
      images: allFile(
        filter: { relativeDirectory: { eq: "property-card-img" } }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const modalTitle = (title) => {
    props.modalTitle(title);
  };

  return (
    <section
      className={classNames('c-property-group-list', {
        'c-property-group-list--background': props.background,
      })}
    >
      <div className="l-container">
        {props.title && (
          <div className="c-property-group-list__intro">
            <h2 className="h-h2">{props.title}</h2>
            {props.description && (
              <p className="text-brand-medium mt-3 sm:text-lg sm:mt-4">
                {props.description}
              </p>
            )}
          </div>
        )}

        <div className="c-property-group-list__grid">
          {props.propertyData.map((data, index) => {
            const imgResult = query.images.edges.find(
              (n) => n.node.base === data.image,
            );

            return (
              <PropertyCard
                inquireModalOpen={props.inquireModalOpen}
                modalTitle={modalTitle}
                key={`property-card-${index}`}
                noInquire={props.noInquire}
                name={data.name}
                description={data.description}
                image={imgResult.node.childImageSharp.gatsbyImageData}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PropertyGroupList;
